import { Route } from '@amazeelabs/react-framework-bridge';
import {
  buildForm,
  buildHtml,
  buildImage,
  buildLink,
} from '@amazeelabs/react-framework-bridge/gatsby';
import { HPageRoute, MenuProvider, ThemeProvider } from '@geored/ui';
import { contactFormSchema } from '@geored/ui/src/utils/contactFormSchema';
import { footerFormSchema } from '@geored/ui/src/utils/footerFormSchema';
import { navigate } from 'gatsby-link';
import React from 'react';

import { SEO } from '../components/SEO';
import Data from '../data/contact.json';
import footerMenuItems from '../data/footer_nav.json';
import mainMenuItems from '../data/main_nav.json';
import reshapeMainMenuData from '../utils/reshapeMainMenuData';

const footerNavItemsReshaped = reshapeMainMenuData(footerMenuItems.nav_item);

const navItemsReshaped = reshapeMainMenuData(mainMenuItems.nav_item);
// @ts-ignore
export const Head = ({ children }) => (
  <>
    <SEO currentPath={''} title={'GeoRED'} />
    {children}
  </>
);

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default function IndexPage() {
  return (
    <ThemeProvider theme={'geored'}>
      <MenuProvider>
        <Route
          definition={HPageRoute}
          input={{
            headerHero: [
              {
                key: 'HeaderHero',
                input: {
                  isSubpage: true,
                  mainMenuItems: navItemsReshaped,
                  bgImage: Data.hero_image,
                  LogoLinkHome: buildLink({
                    href: '/',
                    title: 'geoRED Home page',
                  }),
                  RRPLink: buildLink({
                    href: 'https://coastalbendcog.org/regional-resilience-partnership',
                    title: 'RRP Link',
                  }),
                  title: Data.title,
                },
              },
            ],
            body: [
              {
                key: 'contactBlock',
                input: {
                  title: 'Get in touch',
                  description:
                    'Please provide your name, contact information, and comment and the team will respond soon.',
                  Form: buildForm({
                    initialValues: {
                      name: '',
                      emailAddress: '',
                      phone: '',
                      service: '1',
                    },
                    validationSchema: contactFormSchema,
                    onSubmit(values) {
                      fetch('/', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: encode({
                          'form-name': 'contact',
                          ...values,
                        }),
                      })
                        .then(() => navigate('/thank-you'))
                        .catch((error) => alert(error));
                    },
                  }),
                  sidebarTitle: 'Contact the GeoREDTeam',
                  sidebarDescription:
                    'Found an issue with the data? Have an update that we should know about? Trouble loading a page? The GeoRED team wants to hear from you so that we can ensure our tools and services are as helpful and well-maintained as possible. Reach out to us and we’ll address your concerns as quickly as possible!',
                  ButtonLink: buildLink({ href: '/about', title: 'About Us' }),
                  buttonLinkText: 'About Us',
                  sidebarEmail: 'RRP@tamucc.edu',
                  sidebarAddress:
                    'Harte Research Institute for Gulf of Mexico Studies Texas A&M University-Corpus Christi 6300 Ocean Drive, Unit 5869 Corpus Christi, Texas 78412',
                },
              },
              {
                key: 'image',
                input: {
                  Image: buildImage({
                    alt: 'Map Image',
                    src: Data.map_image,
                  }),
                  wrapperClasses: 'md:my-20',
                },
              },
            ],
            footer: {
              linkedInUrl: 'https://www.linkedin.com/company/coastal-bend-rrp/',
              instagramUrl: 'https://www.instagram.com/coastalbend_rrp/',
              facebookUrl: 'https://www.facebook.com/coastalbend.rrp/',
              footerText:
                'Welcome to GeoRED, a geospatial data portal designed to assist the communities of the Coastal Bend, Texas in practicing smart growth practices by providing regional hazard, modeling, and economic development data.',
              ButtonLink: buildLink({
                href: '/contact',
                title: 'Get in Touch',
              }),
              mainNav: navItemsReshaped,
              footerNav: footerNavItemsReshaped,
              upperFooterSubtitle:
                'Send us your email address and we’ll get in touch',
              upperFooterTitle: 'Send us a Message',
              fundingText:
                'This project was funded by the U.S. Department of Commerce Economic Development Administration and the Texas OneGulf RESTORE Center of Excellence, with federal funding from the Department of the Treasury through the State of Texas under the Resources and Ecosystems Sustainability, Tourist Opportunities, and Revived Economies of the Gulf Coast States Act of 2012 (RESTORE Act). The content, statements, findings, opinions, conclusions, and recommendations are those of the author(s) and do not necessarily reflect the views of the State of Texas or the Treasury.',
              partnershipText:
                'The Regional Resilience Partnership (RRP) is a joint initiative of the Coastal Bend Council of Governments and the Harte Research Institute at Texas A&M University-Corpus Christi.',
              Form: buildForm({
                initialValues: { email: '' },
                validationSchema: footerFormSchema,
                onSubmit(values) {
                  fetch('/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: encode({
                      'form-name': 'follow-up',
                      ...values,
                    }),
                  })
                    .then(() => navigate('/thank-you'))
                    .catch((error) => alert(error));
                },
              }),
              FormText: buildHtml(
                `We care about the protection of your date. Read our <a href="#">Privacy Policy</a>`,
              ),
            },
          }}
          intl={{ defaultLocale: 'en', locale: 'en' }}
        ></Route>
      </MenuProvider>
    </ThemeProvider>
  );
}
